import Menu from '../components/Menu';
import Marquee from "../util/marquee";
import TabsWithIcons from "../components/TabsWithIcons";
import TestimonialsSlider from "../components/TestimonialsSlider";
import OngletsHorizontalOverride from "../components/OngletsHorizontal";
export default {
    init() {
        // JavaScript to be fired on all pages
        console.log('init common.js+---');
        const scrollingTextElements = $('.scrolling-text');
        const logosCarouselElements = $('.logos-carousel');
        const greyScrollingTextElements = $('.grey-scrolling-text');
        const tabsWithIconsLinkElements = $(".ct_secteurs .secteurs a");
        const testimonialsSliderElements = $(".testimonials-slider");
        const headerVideoElements = $('.header-video');
        const ongletsHorizontalElements = $('.fd-block-horizontal-tabs');

        // Fixed header on window scroll
        const $header = jQuery('.container-fixed');
        jQuery(window).scroll(function () {
            if (jQuery(this).scrollTop() > 0) {
                $header.addClass('scroll');
            } else {
                $header.removeClass('scroll');
            }
        });

        this.initHeaderVideo();
        // this.initAnimateMap();
        this.initHeroAnime();

        if(scrollingTextElements.length) {
            scrollingTextElements.each((id, elt) => {
                const scrollingText = new Marquee(elt);
                scrollingText.init();
            })
        }

        if(logosCarouselElements.length) {
            logosCarouselElements.each((id, elt) => {
                const logosCarousel = new Marquee(elt);
                logosCarousel.init();
            })
        }

        if(greyScrollingTextElements.length) {
            greyScrollingTextElements.each((id, elt) => {
                const greyScrollingText = new Marquee(elt);
                greyScrollingText.init();
            })
        }

        if(tabsWithIconsLinkElements.length) {
            tabsWithIconsLinkElements.each((id, elt) => {
                const tabsWithIconsLink = new TabsWithIcons(elt);
                tabsWithIconsLink.init()
            })
        }

        if(testimonialsSliderElements.length) {
            testimonialsSliderElements.each((id, elt) => {
                const testimonialsSlider = new TestimonialsSlider(elt);
                testimonialsSlider.start()
            })
        }

        if(headerVideoElements.length) {
            headerVideoElements.each((id, elt) => {
                const nextSiblingElement = $(elt).next()

                if(nextSiblingElement.hasClass('full-width-cta')) {
                    $(elt).css('padding-bottom', 'unset')
                }
            })
        }

        if(ongletsHorizontalElements.length) {
            ongletsHorizontalElements.each((id, elt) => {
                const ongletsHorizontal = new OngletsHorizontalOverride(elt);
                ongletsHorizontal.init()
            })
        }

        this.initCF7Submit();
        this.initCF7InputTel();
        this.initCF7InputCP();
        this.initCF7CodePostal();
    },

    initHeaderVideo() {
        if (document.querySelector('.header-video .bloc-video')) {
            let btn_yt = document.querySelector('.header-video .bloc-video .link a');

            btn_yt.addEventListener('click', (evt) => {
                evt.preventDefault();
                console.log('btn_yt clicked', btn_yt);

                // Function to create YouTube player
                function createYouTubePlayer(videoId) {
                    // Check if YT is defined and the API is loaded
                    if (typeof YT !== 'undefined' && YT.loaded) {
                        // YouTube API is ready, create the player
                        let player = new YT.Player('yt-video', {
                            videoId: videoId,
                            host: 'https://www.youtube-nocookie.com',
                            playerVars: { rel: 0, showinfo: 0, ecver: 2 },
                            events: {
                                'onReady': onPlayerReady,
                                'onStateChange': onPlayerStateChange
                            }
                        });
                    } else {
                        // If YT is not yet defined, wait for the API to load
                        setTimeout(() => { createYouTubePlayer(videoId); }, 100);
                    }
                }

                // Check if the YouTube API script is already loaded
                if (typeof YT === 'undefined' || !YT.loaded) {
                    // If not loaded, load the script dynamically
                    const script_element = document.createElement("script");
                    script_element.src = 'https://www.youtube.com/iframe_api';
                    document.head.appendChild(script_element);

                    // Set global callback for when API is loaded
                    window.onYouTubeIframeAPIReady = () => {
                        // Extract video ID from href attribute
                        const yt_url = new URL(evt.target.getAttribute('href'));
                        const url_params = new URLSearchParams(yt_url.search);
                        const videoId = url_params.get('v');

                        // Call function to create YouTube player
                        createYouTubePlayer(videoId);
                    };
                } else {
                    // YouTube API is already loaded, directly create the player
                    const yt_url = new URL(evt.target.getAttribute('href'));
                    const url_params = new URLSearchParams(yt_url.search);
                    const videoId = url_params.get('v');

                    createYouTubePlayer(videoId);
                }

                function onPlayerReady(event) {
                    event.target.playVideo();
                }

                function onPlayerStateChange(event) {
                    if (event.data === YT.PlayerState.ENDED) {
                        document.querySelector('.header-video .bloc-video iframe').remove();
                    }
                }
            });
        }
    },


    // initAnimateMap() {
    //     let clicked_on_agence = false;
    //     let timeoutId;

    //     jQuery(document).on('mouseenter', ".mapcard", function (e) {
    //         clearTimeout(timeoutId);
    //     });
    //     jQuery(document).on('mouseleave', ".mapcard", function (e) {
    //         setAgence(0);

    //         if (clicked_on_agence) {
    //             setAgence(clicked_on_agence);
    //         }
    //     });

    //     jQuery(document).on('mouseenter', "svg .pin", function (e) {

    //         let elmt = jQuery(e.target).closest('.pin');
    //         let position = elmt.position();
    //         let agence_id = elmt.attr('id').replace('pin-', '');

    //         setAgence(agence_id, position);
    //     });


    //     jQuery(document).on('mouseleave', "svg .pin", function (e) {

    //         timeoutId = setTimeout(() => {
    //             setAgence(0);

    //             if (clicked_on_agence) {
    //                 setAgence(clicked_on_agence);
    //             }
    //         }, 300);
    //     });

    //     jQuery(document).on('click', ".pin", function(e) {
    //         var agence_id = jQuery(e.target).closest('.pin').attr('id').replace('pin-', '');
    //         setAgence(agence_id);
    //         setClickedOnAgence(agence_id);
    //     });

    //     function setAgence(agence_id, position) {

    //         jQuery('.mapcard').removeClass('show');
    //         jQuery('#mapcard-' + agence_id).addClass('show');

    //         if(position && position.top){

    //             let top = Math.round(position.top);
    //             let left = Math.max(80, Math.round(position.left));
    //             jQuery('#mapcard-' + agence_id).attr('style','position:absolute; top: ' + top + 'px; left: ' + left + 'px;');
    //         }
    //     }
    //     function setClickedOnAgence(agence_id) {
    //         jQuery('.pin.clicked').removeClass('clicked');
    //         if(agence_id) {

    //             // déclic
    //             if(clicked_on_agence === agence_id){
    //                 clicked_on_agence = null;
    //             } else {
    //                 jQuery('#pin-' + agence_id).addClass('clicked');
    //                 clicked_on_agence = agence_id;
    //             }
    //         }
    //     }
    // },

    initHeroAnime () {
        if ($('.hero-anime').length > 0) {
            $('.hero-anime-item').each((index, el) => {
                $(el).on('mouseenter', (e) => {
                    $('.hero-anime-item').removeClass('active');
                    $(el).addClass('active');
                })
            })

            $('.hero-anime-item').eq(0).addClass('active');
        }
    },

    // Empêche les soumissions multiples des formulaires
    initCF7Submit () {
        document.querySelectorAll('.wpcf7 form').forEach(function(form) {
            form.addEventListener( 'submit', function(event) {
                const submitButton = event.target.querySelector('input[type=submit]');
                if (submitButton) {
                    submitButton.disabled = true;
                }
            });
        });

        ['wpcf7invalid', 'wpcf7spam', 'wpcf7mailfailed', 'wpcf7mailsent'].forEach(function(eventType) {
            document.addEventListener(eventType, function(event) {
                const submitButton = event.target.querySelector('input[type=submit]');
                if (submitButton) {
                    submitButton.disabled = false;
                }
            }, false);
        });
    },

    // Formate les champs input[type=tel]
    initCF7InputTel() {
        document.querySelectorAll('html[lang=fr-FR] .wpcf7 form').forEach(function(form) {
            var phoneInputs = form.querySelectorAll('input[type=tel]');

            phoneInputs.forEach(function(input) {
                input.addEventListener('input', function() {
                    var value = input.value;
                    value = value.replace(/[^0-9]/g, '');

                    // Limiter la longueur à 10 caractères
                    if (value.length > 10) {
                        value = value.substring(0, 10);
                    }

                    input.value = value;
                });
            });
        });
    },

    // Formate les champs input[name=CODE_POSTAL]
    initCF7InputCP() {
        document.querySelectorAll('html[lang=fr-FR] .wpcf7 form').forEach(function(form) {
            var cpInputs = form.querySelectorAll('input[name=CODE_POSTAL]');

            cpInputs.forEach(function(input) {
                input.addEventListener('input', function() {
                    var value = input.value;
                    value = value.replace(/[^0-9]/g, '');

                    // Limiter la longueur à 5 caractères
                    if (value.length > 5) {
                        value = value.substring(0, 5);
                    }

                    input.value = value;
                });
            });
        });
    },

    initCF7CodePostal() {
        var _host = 'https://vicopo.selfbuild.fr';
        var _cache = {};
        var _sort = function (a, b) {
            return a.city - b.city;
        };
        var _filter = function () {
            return true;
        };
        $.extend({
            vicopoSort: function ($sort) {
                _sort = $sort;
            },
            vicopoFilter: function ($filter) {
                _filter = $filter;
            },
            vicopoPrepare: function ($cities) {
                $cities = $cities.filter(_filter);
                return $cities.sort(_sort);
            },
            vicopo: function (_input, _done) {
                _input = _input.trim();
                return this.getVicopo(/^\d+$/.test(_input) ? 'code' : 'city', _input, _done);
            },
            codePostal: function (_input, _done) {
                return this.getVicopo('code', _input, _done);
            },
            ville: function (_input, _done) {
                return this.getVicopo('city', _input, _done);
            },
            getVicopo: function (_name, _input, _done) {
                if(_input.length > 1) {
                    _input = _input.trim();
                    _cache[_name] = _cache[_name] || {};

                    if(_cache[_name][_input]) {
                        _done(_input, $.vicopoPrepare(_cache[_name][_input] || []), _name);

                        return;
                    }

                    var _data = {};
                    _data[_name] = _input;
                    return $.getJSON(_host, _data, function (_answer) {
                        _cache[_name][_input] = _answer.cities;
                        _done(_answer.input, $.vicopoPrepare(_answer.cities || []), _name);
                    });
                } else {
                    _done(_input, [], _name);
                }
            }
        });
        $.fn.extend({
            vicopoClean: function () {
                return $(this).each(function () {
                    var _removeList = [];
                    for(var $next = $(this).next(); $next.hasClass('vicopo-answer'); $next = $next.next()) {
                        _removeList.push($next[0]);
                    }
                    $(_removeList).remove();
                });
            },
            vicopoTargets: function () {
                var _targets = [];
                $(this).each(function () {
                    var $target = $(this);
                    $('[data-vicopo]').each(function () {
                        if($target.is($(this).data('vicopo'))) {
                            _targets.push(this);
                        }
                    });
                });
                return $(_targets);
            },
            vicopoTarget: function () {
                return $(this).vicopoTargets().first();
            },
            vicopoFillField: function (_pattern, _city, _code) {
                return $(this).val(
                    _pattern
                        .replace(/(city|ville)/ig, _city)
                        .replace(/(zipcode|code([\s_-]?postal)?)/ig, _code)
                ).vicopoTargets().vicopoClean();
            },
            getVicopo: function (_method, _done) {
                return $(this).keyup(function () {
                    var $input = $(this);
                    $[_method]($input.val(), function (_input, _cities, _name) {
                        if(_input == $input.val()) {
                            _done(_cities, _name, _input);
                        }
                    });
                });
            },
            vicopo: function (_done) {
                return $(this).getVicopo('vicopo', _done);
            },
            codePostal: function (_done) {
                return $(this).getVicopo('codePostal', _done);
            },
            ville: function (_done) {
                return $(this).getVicopo('ville', _done);
            }
        });
        var _fields = 'input, textarea, select';
        $(document).on('keyup change', _fields, function () {
            var $target = $(this);
            var _input = $target.val();
            if($target.data('vicopo-value') !== _input) {
                var _fill = $target.data('vicopo-get');
                var _$targets = $target.data('vicopo-value', _input)
                    .vicopoTargets().each(function () {
                        $(this).hide().vicopoClean();
                    });
                if(_$targets.length && _input.length) {
                    $.vicopo(_input, function (_check, _cities) {
                        if(_check === _input) {
                            _$targets.each(function () {
                                var $repeater = $(this).vicopoClean();
                                var _$template = $repeater.clone();
                                var _click = _$template.data('vicopo-click');
                                _$template.show().removeAttr('data-vicopo');
                                var _$cities = [];
                                $.each(_cities, function () {
                                    var $city = _$template.clone();
                                    var _code = this.code;
                                    var _city = this.city;
                                    $city.addClass('vicopo-answer');
                                    $city.find('[data-vicopo-code-postal]').text(_code);
                                    $city.find('[data-vicopo-ville]').text(_city);
                                    $city.find('[data-vicopo-val-code-postal]').val(_code);
                                    $city.find('[data-vicopo-val-ville]').val(_city);

                                    if (_fill || _click) {
                                        $city.click(function () {
                                            if (_fill) {
                                                $target.vicopoFillField(_fill, _city, _code);
                                            }

                                            $.each(_click, function (_selector, _pattern) {
                                                $(_selector).vicopoFillField(_pattern, _city, _code);
                                            });
                                        });
                                    }

                                    _$cities.push($city);
                                });
                                $repeater.after(_$cities);
                            });
                        }
                    });
                }
            }
        });
        $(_fields).trigger('keyup');
    },

    finalize() {
        let menu = new Menu();
        menu.init();
        console.log('finalize common.js--')
    },
};
